<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con">
                <div class="con_table">
                    <div class="con_top mb-10">
                        <h2><span>■</span>고객 기본정보</h2>
                        <div class="btns2 mb-20"><a class="btn_sms mr-10 pointer" @click="prev()">목록</a></div>
                    </div>
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <!-- <th class="w-120px">고객번호</th>
                                <td class="left pl-20">
                                    {{clientCode}}
                                </td> -->
                                <th class="w-120px">이름</th>
                                <td class="left pl-20" colspan="3">
                                    {{name}}
                                </td>
                                <!-- <th class="w-120px">고객유형</th>
                                <td class="left pl-20">
                                    {{gubun}}
                                </td> -->
                            </tr>
                            <tr>
                                <th>성별</th>
                                <td class="left pl-20">
                                    {{gender}}
                                </td>
                                <th class="w-120px">연락처</th>
                                <td class="left pl-20">
                                    {{phone}}
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td class="left pl-20">
                                    {{birth}}
                                </td>
                                <th>이메일</th>
                                <td class="left pl-20">
                                    {{email}}
                                </td>
                            </tr>
                            <tr>
                                <!-- <th>소속</th>
                                <td class="left pl-20">
                                    {{companyName}}
                                </td> -->
                                <th>유입경로</th>
                                <td class="left pl-20" colspan="3">
                                    {{routeName}}
                                </td>
                            </tr>
                            <tr>
                                <th>파일</th>
                                <td class="left pl-20" colspan="3">
                                    <span v-if="personFiles.attachFileUrl"  @click="newOpen(personFiles.attachFileUrl)" class="underline pointer">
                                    {{ personFiles.attachFileName }}
                                    </span>
                                    <span v-if="personFiles.attachFileUrl2" @click="newOpen(personFiles.attachFileUrl2)" class="ml-20 underline pointer">
                                    {{ personFiles.attachFileName2 }}
                                    </span>
                                    <span v-if="personFiles.attachFileUrl3" @click="newOpen(personFiles.attachFileUrl3)" class="ml-20 underline pointer">
                                    {{ personFiles.attachFileName3 }}
                                    </span>
                                    <span v-if="personFiles.attachFileUrl4" @click="newOpen(personFiles.attachFileUrl4)" class="ml-20 underline pointer">
                                    {{ personFiles.attachFileName4 }}
                                    </span>
                                    <span v-if="personFiles.attachFileUrl5" @click="newOpen(personFiles.attachFileUrl5)" class="ml-20 underline pointer">
                                    {{ personFiles.attachFileName5 }}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00" style="border-top: none;">
                        <tbody>
                            <tr>
                                <th class="w-120px">특이사항</th>
                                <td class="left pl-20 h-40px">
                                    {{memo}}
                                </td>
                            </tr>
                        </tbody>
                    </table> -->

                    <div class="con_top mb-10">
                        <h2><span>■</span>상담정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-80px">상담정보 ID</th>
                                <th>상담센터</th>
                                <th>상담사</th>
                                <th>소속</th>
                                <th>상담구분</th>
                                <th>상담유형</th>
                                <th>상담주제</th>
                                <th>진행현황</th>
                                <th>사용가능회기</th>
                                <!-- <th>관리</th> -->
                                <!-- <th>수납정보</th> -->
                            </tr>
                            <tr v-for="(item, index) of chartList" :key="index">
                                <td>{{item.idx}}</td>
                                <td>{{item.centerName}}</td>
                                <td>{{item.staffName}}</td>
                                <td>{{item.companyName}}</td>
                                <td>{{item.gubun}} {{item.productName}} ({{item.productPrice}} 원)</td>
                                <td>{{item.typeName}}</td>
                                <td>{{item.subjectName}}</td>
                                <td>{{item.clientStatus}}</td>
                                <td>{{item.consCountUse}} 회 (총{{item.consCount}}회)</td>
                                <!-- <td><span class="underline pointer" @click="modify(item.idx)">수정</span> | <span class="underline pointer" @click="remove(item.idx)">삭제</span></td> -->
                                <!-- <td><span class="underline pointer" @click="purchaseDetail(idx)">상세보기</span></td> -->
                            </tr>
                            <tr v-if="chartList.length === 0">
                                <td colspan="9">작성된 차트가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_top mb-10">
                        <h2><span>■</span>상담예약현황</h2>
                        <!-- <div class="btns mb-30">
                        <a class="btn_new2 pointer" @click="consRegister()">상담예약등록</a>
                </div> -->
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>No</th>
                                <th class="w-80px">상담정보 ID</th>
                                <th>상담형태</th>
                                <th>상담일</th>
                                <th>상담시간</th>
                                <th>상담사</th>
                                <th>예약현황</th>
                                <th>일지등록</th>
                                <th>예약등록자</th>
                                <th>등록일</th>
                                <!-- <th>관리</th> -->
                            </tr>
                            <tr v-for="(item, index) of resList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.idxCrmClient}}</td>
                                <td>{{item.contactType}}</td>
                                <td :class="{'underline pointer font_color': item.reportYn === 'Y'}" @click="report_detail(item.idx)">{{item.consDate}}</td>
                                <td>{{checkConsTime(item.consTime)}}</td>
                                <td>{{item.staffName}}</td>
                                <td>{{item.reservationStatus}}</td>
                                <td>{{checkYn(item.reportYn)}}</td>
                                <td>{{item.createStaff}}</td>
                                <td>{{item.createDate}}</td>
                                <!-- <td><span class="underline pointer" @click="detail(item.idxReservation)">상세보기</span></td> -->
                            </tr>
                            <tr v-if="resList.length === 0">
                                <td colspan="10">상담 예약 건이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_table">
                        <div class="page">
                            <ul>
                                <li>
                                    <a class="pointer" @click="prevPaging()"> &lt; </a>
                                </li>
                                <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                    <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                        <a :class="{'bold': index === pageNum}">{{index}}</a>
                                    </span>
                                </li>
                                <li>
                                    <a class="pointer" @click="nextPaging()"> &gt; </a>
                                </li>

                            </ul>
                        </div>
                    </div>

                    <!-- <div class="con_top mb-10">
                        <h2><span>■</span>수납내역</h2>
                    </div>
                    <div class="btns mb-30">
                        <a class="btn_new2 pointer" @click="register()">수납등록</a>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th class="w-120px">수납일자</th>
                                <th class="w-120px">구분</th>
                                <th class="w-120px">수납 방법</th>
                                <th class="w-120px">금액</th>
                                <th class="w-120px">특이사항</th>
                                <th class="w-120px">관리</th>
                            </tr>
                            <tr v-for="(item, index) of purchase" :key="index">
                                <td>{{item.purDate.slice(0,10)}}</td>
                                <td>{{item.status}}</td>
                                <td>{{item.purPayWay}}</td>
                                <td>{{numberWithCommas(item.purPrice)}} 원</td>
                                <td>{{item.memo}}</td>
                                <td><span class="underline pointer" @click="purchase_modify(item.idx)">수정</span><span> | </span><span class="underline pointer" @click="purchase_remove(item.idx)">삭제</span></td>
                            </tr>
                            <tr v-if="purchase.length === 0">
                                <td colspan="6">수납 정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="con_table">
                        <div class="page">
                            <ul>
                                <li>
                                    <a class="pointer" @click="prevPaging2()"> &lt; </a>
                                </li>
                                <li v-for="index in calcMaxPaging2()" :key="index" class="txt-blue">
                                    <span class="pointer" style="padding: 8px;" @click="paging2(index)">
                                        <a :class="{'bold': index === pageNum2}">{{index}}</a>
                                    </span>
                                </li>
                                <li>
                                    <a class="pointer" @click="nextPaging2()"> &gt; </a>
                                </li>

                            </ul>
                        </div>
                    </div> -->

                <div class="con_top mb-10">
                    <h2><span>■</span>특이사항</h2>
                </div>

                <textarea type="text" @keydown.self="enterMemo()" v-model="memo" class="ml-20 w-98per h-100px mb-10" value="" />
                <div class="btns mb-40">
                        <a class="btn_new2 pointer" @click="postMemo()">특이사항 등록</a>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-20" style="table-layout:fixed">
                        <tbody>
                            <tr>
                                <th style="width:20px">No</th>
                                <th style="width:150px">작성일</th>
                                <th>특이사항</th>
                                <th style="width:100px">작성자</th>
                                <th style="width: 100px">센터명</th>
                                <th style="width:100px" v-if="auth === 'MASTER'">센터명</th>
                                <th style="width:30px" v-if="auth === 'MASTER'">관리</th>
                            </tr>
                            <tr v-for="(item, index) of memoList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.formatCreateDate}}</td>
                                <td style="text-align:left;"><span style="white-space: pre-wrap;word-wrap: break-word;">{{item.memo}}</span></td>
                                <td>{{item.createStaff}}</td>
                                <td style="text-align:left;">{{item.centerName}}</td>
                                <td v-if="auth === 'MASTER'"><span class="underline pointer" @click="del(item.idx)">삭제</span></td>
                            </tr>
                            <tr v-if="memoList.length === 0">
                                <td v-if="auth !== 'MASTER'" colspan="4">작성된 특이사항이 없습니다.</td>
                                <td v-if="auth === 'MASTER'"  colspan="5">작성된 특이사항이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        clientCode: '',
        eapType: '',
        name: '',
        gender: '',
        birth: '',
        email: '',
        phone: '',
        idxCrmMetaRoute: -1,
        companyName: '',
        routeName: '',
        memo: '',
        centerName: '',
        staffName: '',
        gubun: '',
        typeName: '',
        subjectName: '',
        clientStatus: '',
        consCount: 0,
        consCountUse: 0,
        consCountRest: 0,
        productName: '',
        productPrice: 0,
        typeName: '',
        subjectName: '',
        // 상담일지목록
        reportList: [],
        reportYn: 'Y',
        memoList: [],
        chartList: [],
        resList: [],
        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 5,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        auth: '',

        purchase: [],
        // 페이징에 필요한 변수들
        pageNum2: 1,
        pageSize2: 5,
        listLength2: 0, // 리스트 size
        maxPagingCoun2t: 1, // 최대 페이지 수
        maxPagePart2: 1, // 최대 파트가 몇번째인지
        pagePart2: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)

        idxCrmStaff: -1,
        //person 파일목록
        personFiles: {
            attachFileUrl: '',
            attachFileUrl2: '',
            attachFileUrl3: '',
            attachFileUrl4: '',
            attachFileUrl5: '',
            attachFileName: '',
            attachFileName2: '',
            attachFileName3: '',
            attachFileName4: '',
            attachFileName5: '',
        }

    }),

     /* computed: {
        enterMemo() {
            if (this.memo.length > 102) {
                return this.memo + '\n'
            }
        }
    }, */

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getCustomerData(this.idx)
        this.auth = localStorage.getItem("auth")
    },

    methods: {

        checkConsTime(data) {
            if(data.length >= 84) {
                return data.substring(0,84) + '\n' + data.substring(84)
            } else {
                return data
            }
        }, 

        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },

        checkYn(data) {
            if (data === 'Y') {
                return "등록"
            } else {
                return "미등록"
            }
        },

        enter(data) {
            data.replace(/(?:\r\n|\r|\n)/g, '<br />')
            // data.split('\n').join('<br />');
            return data
        },

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getChartReservationList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getChartReservationList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging2() {
            if (this.pageNum2 === this.maxPagingCount2) {
                return false
            } else {
                this.pageNum2 += 1
                this.getPurchase()
            }
        },

        // 이전 페이징
        prevPaging2() {
            if (this.pageNum2 === 1) {
                return false
            } else {
                this.pageNum2 -= 1
                this.getPurchase()
            }
        },
        
        paging2(index) {
            this.pageNum2 = index
            this.getPurchase()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging2() {
            if (this.maxPagingCount2 > this.pagePart2 * 10) {
                this.maxPagePart2 = this.pagePart2 * 10
            } else {
                this.maxPagePart2 = this.maxPagingCount2
            }
            var pagingArray = []
            for (var i = ((this.pagePart2 - 1) * 10) + 1; i <= this.maxPagePart2; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging2() {
            if (this.pageNum2 === this.maxPagingCount2) {
                return false
            } else {
                this.pageNum2 += 1
                this.getPurchase()
            }
        },

        // 상담일지 목록
        getChartReservationList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                idxCrmPerson: this.idxCrmPerson,
                idxCrmStaff: this.idxCrmStaff
            }

            this.axios.get('/api/v1/cons/chart/list', {
                    params: params
                })
                .then(res => {
                    console.log('chart/list/res', res)
                    if (res.data.err === 0) {
                        this.resList = res.data.resList

                        if (res.data.resListCount) {
                            this.listLength = res.data.resListCount
                        }
                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)

                    } else {
                        //alert('검색 결과가 없습니다.')
                        this.resList = []
                    }
                })
                .catch(err => {
                    alert(err)
                    console.log(err)
                    if (err.response.data.path === '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        dateCheck(date) {
            if (date !== null) {
                return date.slice(0, 10)
            } else {
                return '-'
            }
        },

        contentsCheck(contents) {
            if (contents !== null) {
                if (contents.length > 100) {
                    contents = contents.substr(0, 100) + "..."
                }
            }
            return contents
        },

        postMemo() {

            var vo = {
                memo: this.memo,
                idxCrmPerson: this.idxCrmPerson,
            }

            this.axios.post('/api/v1/client/memo', vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('메모 등록이 완료되었습니다.')
                        //this.$router.push('/origin/customer_manage')
                        this.$router.go(this.$router.currentRoute)
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        getMemoList(idx) {
            let params = {
                idxCrmPerson: idx,
                idxCrmStaff: this.idxCrmStaff
            }

            this.axios.get(`/api/v1/client/memo/list`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.memoList = res.data.memoList
                        
                        for(var memoList of res.data.memoList) {
                            console.log('memo', memoList)
                            console.log('memo.length', memoList.memo.length)
                        }

                    } else {
                        this.memoList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getChartList(idx) {
            var params = {
                idxCrmPerson: idx,
                idxCrmStaff: this.idxCrmStaff
            }
            this.axios.get(`/api/v1/client/chart/list`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.chartList = res.data.chartList
                    } else {
                        this.chartList = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getPersonData(idx) {
            var params = {
                idx: idx
            }
            this.axios.get(`/api/v1/client/person/${idx}`, {})
                .then(res => {
                    if (res.data.err === 0) {

                        this.name = res.data.personVO.name
                        this.gender = res.data.personVO.gender
                        this.birth = res.data.personVO.birth
                        this.idxCrmMetaRoute = res.data.personVO.idxCrmMetaRoute
                        this.routeName = res.data.personVO.routeName
                        this.email = res.data.personVO.email
                        this.phone = res.data.personVO.phone
                        this.idxCrmStaff = res.data.idxCrmStaff

                        this.personFiles.attachFileUrl = res.data.personVO.attachFileUrl
                        this.personFiles.attachFileUrl2 = res.data.personVO.attachFileUrl2
                        this.personFiles.attachFileUrl3 = res.data.personVO.attachFileUrl3
                        this.personFiles.attachFileUrl4 = res.data.personVO.attachFileUrl4
                        this.personFiles.attachFileUrl5 = res.data.personVO.attachFileUrl5
                        this.personFiles.attachFileName = res.data.personVO.attachFileName
                        this.personFiles.attachFileName2 = res.data.personVO.attachFileName2
                        this.personFiles.attachFileName3 = res.data.personVO.attachFileName3
                        this.personFiles.attachFileName4 = res.data.personVO.attachFileName4
                        this.personFiles.attachFileName5 = res.data.personVO.attachFileName5
            

                        this.getChartList(res.data.personVO.idx)
                        this.getMemoList(res.data.personVO.idx)
                        this.getChartReservationList()
                        this.getPurchase()
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        getCustomerData(idx) {
            var params = {
                reportYn: this.reportYn
            }
            this.axios.get(`/api/v1/client/${idx}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.reportList = res.data.reportList
                        /* for (var item of Object.keys(res.data.clientVO)) {
                            if (typeof this[item] !== 'undefined') {
                                this[item] = res.data.clientVO[item]
                                console.log(this[item])
                                console.log(res.data.clientVO[item])
                            } 

                            if (res.data.clientVO.gubun === '기타') {
                                this.productPrice = res.data.clientVO.etcPrice
                            }
                        } */
                        this.clientCode = res.data.clientVO.clientCode
                        if (res.data.clientVO.gubun === '기타') {
                            this.productPrice = res.data.clientVO.etcPrice
                        }
                        this.getPersonData(res.data.clientVO.idxCrmPerson)
                        this.idxCrmPerson = res.data.clientVO.idxCrmPerson
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 수납내역
        getPurchase() {

            let params = {
                pageNum: this.pageNum2,
                pageSize: this.pageSize2
            }
            this.axios.get(`/api/v1/client/chart/pur/b2c/${this.idxCrmPerson}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.purchase = res.data.purchase

                        if (res.data.purchaseSize) {
                            this.listLength2 = res.data.purchaseSize
                        }
                        this.maxPagingCount2 = Math.ceil(this.listLength2 / this.pageSize2)
                        this.maxPagePart2 = Math.ceil(this.maxPagingCount2 / 10)
                        this.pagePart2 = Math.ceil(this.pageNum2 / 10)

                        console.log('maxPagingCount2', this.maxPagingCount2)
                        console.log('maxPagePart2', this.maxPagePart2)
                        console.log('pagePart2', this.pagePart2)
                    } else {
                        this.purchase = []
                    }
                })
                .catch(err => {
                    console.log(err.response)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 수납등록 페이지로 이동
        register () {
            this.$router.push(`/origin/customer_acceptance_register_b2c?idxCrmClient=${this.idxCrmClient}`)
        },

        // 고객관리 페이지로 돌아가기
        prev() {
            //this.$router.push('/origin/customer_manage')
            history.back()
        },

        // 수납정보상세 페이지로 이동
        purchaseDetail(idx) {
            // 수납정보 상세보기
            this.$router.push(`/origin/customer_acceptance_view_b2c?idxCrmClient=${idx}`)
        },

        // 수납수정 페이지로 이동
        purchase_modify (idx) {
            this.$router.push(`/origin/customer_acceptance_modify_b2c?idx=${idx}`)
        },

        // 수납정보 삭제
        purchase_remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/client/pur/${idx}`, {})
                    .then(res => {
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            this.$router.go(this.$router.currentRoute)
                        } else {
                            alert('삭제할 수 없습니다.')
                        }
                    })
                    .catch(err => {
                        console.log(err.response)
                    if(err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                    })
            } else {
                return false
            }
        },

        // 상세보기 페이지로 이동
        detail(idx) {
            //window.open(`/origin/counselor_journey_info?idx=${idx}`, '_blank')
            this.$router.push(`/origin/counselor_journey_info?idx=${idx}`)
        },
        // 고객 수정페이지로 이동
        /* modify() {
            this.$router.push(`/origin/customer_manage_modify?idx=${this.idx}`)
        }, */
        modify(idx) {
            this.$router.push(`/origin/customer_manage_modify?idx=${idx}`)
        },

        // 고객 삭제
        remove(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/client/${idx}`, {})
                    .then(res => {
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            this.$router.push('/origin/customer_manage')
                        } else {
                            alert('삭제할 수 없습니다.')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                return false
            }
        },

        del(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/client/memo/${idx}`, {})
                    .then(res => {
                        if (res.data.err === 0) {
                            alert(res.data.result)
                            this.$router.go(this.$router.currentRoute)
                        } else {
                            alert('삭제할 수 없습니다.')
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                return false
            }
        },

        // 상담일지상세보기 페이지로 이동
        report_detail(idx) {
            //this.$router.push('/origin/counselor_journey_info')
            this.$router.push(`/counselor/journal_info?idx=${idx}`)
            //window.open(`/origin/counselor_journey_info?idx=${idx}`, '_blank')
        },
        
        consRegister() {
            // 상담예약 등록페이지로 이동
            this.$router.push(`/origin/consult_reserve_register?idxCrmPerson=${this.idxCrmPerson}`)
        },
        /**
         * 새페이지 오픈
         */
        newOpen(url){
            if(url){
                window.open(url,'_blank');
            }
            else{
                alert('실제 파일이 존재하지 않습니다.');
            }
        },
    }
}
</script>
